import { isInvalidNumsCustom } from '@/js/lib/input-check.js'
import errorText from '@/js/lib/error-handle-text.js'
import regression from 'regression'
import _ from 'lodash'
var jStat = require('jstat').jStat

// Input Handling
const isXallEqual = (xData) => {
    if (_.uniq(xData).length <= 1) {
        return true
    }
}

const isInBounds = (xInput, data) => {
    const xData = getDataX(data)
    const xMin = _.min(xData)
    const xMax = _.max(xData)
    if (_.lt(xInput, xMin) || _.gt(xInput, xMax)) {
        alert(errorText.regressionAlert.xBounds)
        return true
    }
}

const dataHasNegative = (data) => {
    const xData = getDataX(data)
    const yData = getDataY(data)
    var vals = _.concat(xData, yData)
    const invalid = _.some(vals, number => (number < 0))
    return invalid
}

const formatDataCheck = (data) => {
    var errors = []
    var dataValues = []
    for (var i in data) {
        var x = _.toString(data[i].inputValueX)
        var y = _.toString(data[i].inputValueY)
        var strX = _.isEmpty(x)
        var strY = _.isEmpty(y)
        if (isInvalidNumsCustom(_.toNumber(x), _.toNumber(y))) {
            errors.push(errorText.regressionAlert.invalidInputs)
        } else if ((strX && !strY) || (!strX && strY)) {
            errors.push(errorText.regressionAlert.missingPairs)
        } else if (!strX && !strY) {
            dataValues.push(data[i])
        }
    }
    var parsedData = parsedInputs(dataValues)
    const xData = getDataX(parsedData)
    const yData = getDataY(parsedData)
    if (_.size(xData) < 3 || _.size(yData) < 3) {
        errors.push(errorText.regressionAlert.minPairs)
    }
    if (isXallEqual(xData)) {
        errors.push(errorText.regressionAlert.xEqual)
    }
    return { output: parsedData, errors }
}

// Data Formatting
const formatTestData = (dataX, dataY) => {
    const outputData = _.zipWith(dataX, dataY, (inputValueX, inputValueY) => ({ inputValueX, inputValueY }))
    return outputData
}

const parsedInputs = (data) => {
    const numData = data.map(v => {
        return { inputValueX: _.toNumber(v.inputValueX), inputValueY: _.toNumber(v.inputValueY) }
    })
    return numData
}

const getDataY = (data) => {
    var yData = data.map(v => { return v.inputValueY })
    return yData
}

const getDataX = (data) => {
    var xData = data.map(v => { return v.inputValueX })
    return xData
}

const groupValsArr = (data) => {
    return data.map(v => [_.toNumber(v.inputValueX), _.toNumber(v.inputValueY)])
}

const formatChartData = (data) => {
    return data.map(v => {
        return { x: _.toNumber(v.inputValueX), y: _.toNumber(v.inputValueY) }
    })
}

const formatRegrChartData = (data) => {
    var outputData = data.map(v => {
        return { x: v[0], y: v[1] }
    })
    return outputData
}

// Calculations
const getMaxDomainX = (data) => {
    const max = _.max(data)
    return Math.ceil((max + 1) / 10) * 10
}

const getMinDomainX = (data) => {
    return Math.floor((_.min(data) + 1) / 10) * 10
}

const convertCoeff = (coeff) => {
    const prob = coeff / 100
    return (prob + (1 - prob) / 2)
}

const calculateRegression = (data) => {
    const arr = groupValsArr(data)
    const result = regression.linear(arr)
    return result
}

const calculateSums = (data) => {
    const xSum = _.sum(getDataX(data))
    const ySum = _.sum(getDataY(data))
    var xSumSqd = _.sum(data.map(v => _.multiply(v.inputValueX, v.inputValueX)))
    var ySumSqd = _.sum(data.map(v => _.multiply(v.inputValueY, v.inputValueY)))
    var xySum = _.sum(data.map(v => _.multiply(v.inputValueX, v.inputValueY)))
    return {
        xSum, ySum, xSumSqd, ySumSqd, xySum
    }
}

const calculateSS = (data) => {
    const s = calculateSums(data)
    const n = data.length

    const SSx = s.xSumSqd - s.xSum * s.xSum / n
    const SSy = s.ySumSqd - s.ySum * s.ySum / n
    const SSxy = s.xySum - s.xSum * s.ySum / n

    var m = SSxy / SSx
    var b = (s.ySum - m * s.xSum) / n
    const SSe = (s.ySumSqd - b * s.ySum - m * s.xySum) / (n - 2)

    m = Number(m.toFixed(4))
    b = Number(b.toFixed(4))

    return {
        SSx, SSy, SSxy, SSe, m, b
    }
}

const calculateXbar = (data) => {
    var xBar = _.sum(getDataX(data)) / data.length
    xBar = Number(xBar.toFixed(4))
    return xBar
}

const calculateYbar = (data) => {
    var yBar = _.sum(getDataY(data)) / data.length
    yBar = Number(yBar.toFixed(4))
    return yBar
}

const calculateR = (data) => {
    var ss = calculateSS(data)
    var r = ss.SSxy / Math.sqrt(ss.SSx * ss.SSy)
    r = Number(r.toFixed(4))
    return r
}

// HT calculations
const calculateTforR = (data) => {
    var r = calculateR(data.items)
    const t = r / Math.sqrt((1 - r * r) / (data.items.length - 2))
    return _.toNumber(t.toFixed(2))
}

const calculateTforM = (data) => {
    var ss = calculateSS(data.items)
    const t = data.regrEquation[0] / Math.sqrt(ss.SSe / ss.SSx)
    return _.toNumber(t.toFixed(2))
}

// CI calculations
const calculateCI = (value, data, clInput, xInput) => {
    const n = data.output.n
    const xBar = data.output.xBar
    const df = data.df
    const m = data.regrEquation[0]
    const b = data.regrEquation[1]
    const ss = calculateSS(data.items)
    const prob = convertCoeff(clInput)
    const t = jStat.studentt.inv(prob, df)
    const tFixed = Number(t.toFixed(2))
    const yHat = b + m * xInput
    var offset
    var lowerLimit
    var upperLimit

    // console.log('calculateCI vars : ')
    // console.log({ value, n, xBar, df, m, b, ss, prob, t, tFixed, yHat })

    if (value === 'indvResponse') {
        offset = tFixed * Math.sqrt(ss.SSe * (1 + 1 / n + (xInput - xBar) * (xInput - xBar) / ss.SSx))
        lowerLimit = yHat - offset
        upperLimit = yHat + offset
    } else if (value === 'meanResponse') {
        offset = tFixed * Math.sqrt(ss.SSe * (1 / n + (xInput - xBar) * (xInput - xBar) / ss.SSx))
        lowerLimit = yHat - offset
        upperLimit = yHat + offset
    } else if (value === 'M') { // usage: M-CI
        offset = tFixed * Math.sqrt(ss.SSe / ss.SSx)
        lowerLimit = m - offset
        upperLimit = m + offset
    }

    lowerLimit = Number(lowerLimit.toFixed(4))
    upperLimit = Number(upperLimit.toFixed(4))
    return { lowerLimit, upperLimit }
}

// Regression lines calculation (above and below)
const calculateRpoints = (value, data, clInput, xInput) => {
    const n = data.output.n
    const xData = getDataX(data.items)
    const xMin = _.min(xData)
    const xMax = _.max(xData)
    const xBar = data.output.xBar
    const df = data.df
    const m = data.regrEquation[0]
    const b = data.regrEquation[1]
    const ss = calculateSS(data.items)
    const prob = convertCoeff(clInput)
    const t = jStat.studentt.inv(prob, df)
    const tFixed = Number(t.toFixed(2))

    // console.log('calculateRpoints vars : ')
    // console.log({ n, xData, xMin, xMax, xBar, df, m, b, ss, prob, t, tFixed })

    const deltaX = (xMax - xMin) / 10
    var x = xMin
    var yHat
    var offset
    var xVals = []
    var yValsAbove = []
    var yValsBelow = []

    for (let i = 0; i < 11; i++) {
        xVals[i] = x
        yHat = b + m * x
        if (value === 'indvResponse') {
            offset = tFixed * Math.sqrt(ss.SSe * (1 + 1 / n + (x - xBar) * (x - xBar) / ss.SSx))
        } else if (value === 'meanResponse') {
            offset = tFixed * Math.sqrt(ss.SSe * (1 / n + (x - xBar) * (x - xBar) / ss.SSx))
        }
        yValsAbove[i] = yHat + offset
        yValsBelow[i] = yHat - offset
        x = x + deltaX
    }

    // Format the data for 2 graph lines
    const xyPointsBelow = _.zipWith(xVals, yValsBelow, (x, y) => ({ x, y }))
    const xyPointsAbove = _.zipWith(xVals, yValsAbove, (x, y) => ({ x, y }))

    // Getting x line points between regression curves
    const xPointVal = getxGraphLine(xVals, xInput)
    var index = _.indexOf(xVals, xPointVal)
    var yBelow = xyPointsBelow[index].y
    var yAbove = xyPointsAbove[index].y
    // var xLinePoints = _.zipObject(['x', 'y', 'y0'], [xInput, yBelow, yAbove])
    var xLine = [{ x: xInput, y: yBelow }, { x: xInput, y: yAbove }]

    return { xyPointsBelow, xyPointsAbove, xLine }
}

const getxGraphLine = (points, goal) => {
    const output = points.reduce((prev, curr) => Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
    return output
}

// Render
const sign = (m) => {
    if (m < 0) {
        return ''
    } else {
        return '+'
    }
}

export default {
    formatTestData,
    formatDataCheck,
    isInBounds,
    dataHasNegative,
    formatChartData,
    formatRegrChartData,
    getDataY,
    getDataX,
    getMaxDomainX,
    getMinDomainX,
    groupValsArr,
    calculateRegression,
    calculateSS,
    calculateXbar,
    calculateYbar,
    convertCoeff,
    calculateR,
    calculateTforR,
    calculateTforM,
    calculateCI,
    calculateRpoints,
    sign
}
